<template>
  <v-layout max-width class="flex-column" style="min-height:100%;">
    <!-- <div class="flex-y-center flex-justify-between px-2" style="border-bottom:1px solid #e8e8e8 ">
    </div>-->
    <!-- <div class=" flex-1"> -->
    <!-- <div class=" flex-1 flex-justify-between max-height" style="min-height:100%;"> -->
    <v-row dense>
      <!-- <v-col cols="12" :md="active&&active.length?4:12"> -->
      <v-col cols="12" :md="4">
        <v-card class="max-height elevation-0">
          <div class="flex-0 flex-shrink-0">
            <div
              class="px-4 pt-3 pb-3 mb-4 flex-y-center"
              style="border-bottom:1px solid #eee !important;"
            >
              <v-btn
                dark
                small
                color="#00bea9"
                depressed
                height="30"
                width="100"
                class="mr-2"
                @click="departmentDialog.data = {},departmentDialog.departId=[],departmentDialog.show=true"
              ><v-icon size="16">mdi-plus</v-icon>新增部门</v-btn>
              <v-btn
                :disabled="!(selection&&selection.length)"
                dark
                small
                color="error"
                depressed
                min-width="30"
                height="30"
                class="mr-2"
                @click="departmentDel"
              ><v-icon size="16">mdi-delete-outline</v-icon>批量删除</v-btn>
            </div>

            <!-- item-children="children" active open selection-->
            <div class=" px-4 mb-3">
              <form-item
                v-model="treeSearch"
                class="mr-0"
                placeholder="部门名称"
                type="text"
                @input="setOpen"
              />
            </div>
            <v-treeview
              v-model="selection"
              class="mx-2"
              :active.sync="active"
              :open.sync="open"
              activatable
              :items="allTrees"
              transition
              selected-color="primary"
              item-text="departName"
              selectable
              selection-type="independent"
              :search="treeSearch"
              @update:active="chooseTree"
              @input="testtest"
            />
            <!-- :search="treeSearch" -->
            <!-- :filter="treeFilter" -->
            <!-- selection-type="independent" -->
            <!-- <v-treeview
              class="mx-3"
              :active.sync="active"
              @update:active="chooseTree"

              activatable
              :items="trees"
              :open.sync="open"
              item-text="departName"
              transition
              selection-type="independent"
              selected-color="primary"
              selectable
              v-model="selection"
              :load-children="fetchTrees"
            >
            </v-treeview> -->
            <!-- return-object -->
          </div>
        </v-card>
      </v-col>
      <!-- v-if="active&&active.length" -->
      <v-col cols="12" md="8">
        <v-card class="max-height elevation-0 ml-1">
          <!-- class=" px-3 pt-4 pb-3 mb-4 flex-y-center" style="border-bottom:1px solid #eee !important;" -->
          <div
            class="flex-y-center flex-justify-between px-2 pt-2"
            style="border-bottom:1px solid #e8e8e8 "
          >
            <v-tabs v-model="tab" class="">
              <v-tab class="pb-1">基本信息</v-tab>
              <v-tab class="pb-1">成员列表</v-tab>
            </v-tabs>
          </div>
          <v-tabs-items v-if="active&&active.length" v-model="tab">
            <v-tab-item class=" pa-12 mr-8">
              <form @submit.prevent="departmentDialoSubmit('edit')">
                <div class="flex-y-center mb-5">
                  <span
                    class="flex-0 flex-shrink-0 flex-justify-end mr-2 body-1"
                    style="width:110px;"
                  ><span class="red--text ml-1">*</span>部门名称：</span>
                  <form-item
                    v-model="content.data.departName"
                    class="mr-0"
                    placeholder="部门名称"
                    required
                    type="text"
                  />
                </div>
                <div class="flex-y-center mb-5">
                  <span
                    class="flex-0 flex-shrink-0 flex-justify-end mr-2 body-1"
                    style="width:110px;"
                  >上级部门：</span>
                  <!-- <div class="default-input py-1 grey lighten-4 flex-y-center" color="primary" style="height:34px;">
                  <span v-if="content.departId&&content.departId.length" v-text="backParentName()"></span>
                  <span v-else class="grey--text text--darken-1">-</span>
                </div> -->
                  <v-menu
                    bottom
                    max-height="300"
                    offset-overflow
                    transition="slide-y-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <div class="default-input flex-y-center flex-justify-between py-1" color="primary" style="height:36px;" v-on="on">
                        <span v-if="content.departId&&content.departId.length" v-text="backParentName()" />
                        <span v-else class="grey--text text--lighten-1">选择上级部门</span>
                        <v-icon v-if="content.departId&&content.departId.length" size="16" @click.stop="content.departId=[],$forceUpdate()">mdi-close</v-icon>
                      </div>
                    </template>
                    <v-card class="elevation-0">
                      <v-treeview
                        activatable
                        :items="allTrees"
                        item-text="departName"
                        item-disabled="_disabled"
                        :active.sync="content.departId"
                      />
                    </v-card>
                  </v-menu>
                </div>
                <div class="flex-y-center pt-2 mb-5">
                  <span
                    class="flex-0 flex-shrink-0 flex-justify-end mr-2 body-1"
                    style="width:110px;"
                  />
                  <v-btn
                    dark
                    small
                    type="submit"
                    color="primary"
                    depressed
                    min-width="80"
                    height="30"
                    :loading="btnLoading"
                    class="mr-2"
                  >确认</v-btn>
                </div>
              </form>
            </v-tab-item>
            <v-tab-item>
              <div
                class="px-3 pt-4 pb-2 flex-justify-between"
                style=""
              >
                <div>
                  <v-btn
                    v-if="allUserList&&allUserList.length"
                    dark
                    small
                    color="#00bea9"
                    depressed
                    min-width="30"
                    height="30"
                    class="mr-2"
                    @click="openActivation"
                  >选择用户</v-btn>
                  <v-btn v-else depressed height="30" disabled>
                    暂无可选用户
                  </v-btn>
                  <!-- <v-btn
                    v-if="selected&&selected.length"
                    dark
                    small
                    @click="delShow"
                    color="#f96767"
                    depressed
                    min-width="30"
                    style="height:32px; padding:0;"
                    class="ml-2"
                  >
                    <v-icon size="20">mdi-delete</v-icon>
                  </v-btn> -->
                </div>
                <div class="flex-y-center">
                  <div class="flex-y-center mr-1" style="width:120px;">
                    <form-item
                      v-model="search.data.username"
                      class="mr-0"
                      placeholder="账号"
                      type="text"
                      allow-close
                      @onEnter="searchLoading()"
                    />
                  </div>
                  <div class="flex-y-center mr-1" style="width:120px;">
                    <form-item
                      v-model="search.data.realname"
                      class="mr-0"
                      placeholder="姓名"
                      type="text"
                      allow-close
                      @onEnter="searchLoading()"
                    />
                  </div>
                  <v-btn
                    dark
                    small
                    color="primary"
                    depressed
                    min-width="34"
                    class="mr-1"
                    style="padding:0;"
                    height="30"
                    :loading="listLoading"
                    @click="searchLoading()"
                  >
                    <v-icon size="16">mdi-magnify</v-icon>
                  </v-btn>
                  <v-btn dark small color="grey lighten-1" :loading="listLoading" depressed min-width="34" height="30" style="padding:0;" @click="search.data={},searchLoading()">
                    <v-icon size="16">mdi-restore</v-icon>
                  </v-btn>
                </div>
              </div>
              <!-- show-select
                v-model="selected"
                checkbox-color="#4682b4" -->
              <v-data-table
                :headers="headers"
                :items="showList.slice((search.pageSize*(search.pageNo-1)),(search.pageSize*search.pageNo))"
                :items-per-page="search.pageSize"
                class="pb-5 max-width px-3 header-grey"
                hide-default-footer
                item-key="id"
                no-data-text="暂无查询数据"
                :loading="listLoading"
              >
                <!-- <template v-slot:header.organization>{{tab==1?'组织角色':'组织单位'}}</template> -->
                <template v-slot:header.organization>组织角色</template>

                <template v-slot:item.rowIndex="{index}">
                  {{ (index+1)+(search.pageSize*(search.pageNo-1)) }}
                </template>

                <template v-slot:item.username="{item}">
                  <div class=" flex-y-center">
                    {{ item.username }}
                    <v-chip
                      v-if="item.managerFlag==1"
                      class="ml-1 px-1 caption"
                      color="success"
                      label
                      x-small
                      text-color="white"
                    >
                      主
                    </v-chip>
                  </div>
                </template>
                <template v-slot:item.status="{item}">
                  <!-- <span :class="item.status==2?'error--text':''">{{ item.status==1?'可用':(item.status==2?'冻结':'-') }}</span> -->
                  <!-- 优先级 删除、禁用、锁定、过期 -->
                  <!-- expirationTime判断是否过期 -->
                  <span v-if="item.delFlag ==1 " class="error--text">已删除</span>
                  <!-- <span v-else-if="item.lockFlag == 1" class="error--text">锁定</span>
                  <span v-else :class="item.status==2?'error--text':''">{{ item.status==1?'可用':(item.status==2?'禁用':'-') }}</span> -->
                  <span v-else-if="item.status == 2" class="error--text">禁用</span>
                  <span v-else-if="item.lockFlag == 1" class="error--text">锁定</span>
                  <span v-else-if="item.expirationTime && (new Date().getTime()>(new Date(item.expirationTime).getTime() + 86400000))" class="error--text">
                    过期
                  </span>
                  <span v-else>{{ item.status==1?'可用':'-' }}</span>

                </template>
                <template v-slot:item.active="{item}">
                  <v-icon v-if="item.managerFlag==1" color="error" size="20" title="取消主管" class=" mr-1" @click="operation($api.setDepartManager,'post','取消主管',{departId:content.data.id,managerFlag:'0',userId:item.id})">mdi-account-alert</v-icon>
                  <v-icon v-else size="20" color="primary" title="设为主管" class=" mr-1" @click="operation($api.setDepartManager,'post','设为主管',{departId:content.data.id,managerFlag:'1',userId:item.id})">mdi-account-check</v-icon>
                  <v-icon color="error" size="20" title="移除" class=" mr-1" @click="operation($api.deleteUserInDepart,'delete','移除',{depId:content.data.id,userId:item.id})">mdi-account-arrow-right-outline</v-icon>

                  <!-- <a class=" error--text" @click="operation(item.id)">移除</a> -->
                  <!-- <v-menu offset-y bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <a v-bind="attrs" v-on="on">更多</a>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title>禁用</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>解锁</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>恢复</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu> -->
                </template>
              </v-data-table>
              <div class="mt-2 pr-2 mb-5 max-width  flex-justify-end">
                <pagination v-model="search.pageNo" :size="search.pageSize" :total="total" @changePageSize="(e)=>{search.pageSize=e,$forceUpdate()}" />
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <!-- </div> -->
    <!-- 添加部门 start -->
    <v-dialog v-model="departmentDialog.show" width="500">
      <form class="max-height flex-column overflow-hidden" @submit.prevent="departmentDialoSubmit()">
        <v-card>
          <v-card-title class="text-h5 lighten-2">{{ departmentDialog.data.id?'编辑部门':'添加部门' }}</v-card-title>
          <v-divider />
          <v-card-text class="flex-center px-12 body-1">
            <div class="mt-9 max-width">
              <div class="flex-y-center mb-5">
                <span
                  class="flex-0 flex-shrink-0 flex-justify-end mr-2 body-1"
                  style="width:86px;"
                ><span class="red--text ml-1">*</span>部门名称：</span>
                <form-item
                  v-model="departmentDialog.data.departName"
                  class="mr-0"
                  required
                  placeholder="部门名称"
                  type="text"
                />
              </div>
              <div class="flex-y-center mb-5">
                <span
                  class="flex-0 flex-shrink-0 flex-justify-end mr-2 body-1"
                  style="width:86px;"
                >上级部门：</span>
                <v-menu
                  bottom
                  max-height="300"
                  offset-overflow
                  transition="slide-y-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <div class="default-input py-1 flex-y-center flex-justify-between" style="height:34px;" color="primary" v-on="on">
                      <input
                        placeholder="上级部门"
                        readonly
                        class="max-width"
                        type="text"
                        :value="(departmentDialog.departId.length&&departmentDialog.departId[0].departName)||''"
                      >
                      <v-icon v-if="departmentDialog.departId&&departmentDialog.departId.length" size="16" @click.stop="departmentDialog.departId=[],$forceUpdate()">mdi-close</v-icon>
                    </div>
                  </template>
                  <!-- trees -->
                  <v-card class="elevation-0">
                    <v-treeview
                      activatable
                      :items="allTrees"
                      item-text="departName"
                      :active.sync="departmentDialog.departId"
                      return-object
                    />
                  </v-card>
                </v-menu>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn depressed width="100" height="30" @click="departmentDialog.show = false,departmentDialog.data={},departmentDialog.departId=[]">取消</v-btn>
            <v-btn color="primary" height="30" :loading="departmentDialog.loading" type="submit" class="ml-2" depressed width="100">确认</v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <!-- 添加部门 end -->

    <!-- 添加已有用户 statr -->
    <v-dialog v-model="activation.show" width="1300">
      <v-card class=" flex-column flex-justify-between" style="height:760px;">
        <v-card-title class=" flex-0 flex-shrink-0 font-weight-bold">添加已有用户</v-card-title>
        <v-divider />
        <v-card-text class=" flex-1 py-5 overflow-hidden">
          <div class=" flex-justify-between max-height">
            <div class=" flex-column flex-1  overflow-auto" style="border:1px solid #f2f2f2;">

              <div class="flex-justify-end flex-y-center py-2">
                <div class="flex-y-center mr-1" style="width:120px;">
                  <form-item
                    v-model="activation.left.search.data.username"
                    class="mr-0"
                    placeholder="用户账号"
                    type="text"
                    @input="searchDialogList('left')"
                  />
                </div>
                <div class="flex-y-center mr-1" style="width:120px;">
                  <form-item
                    v-model="activation.left.search.data.realname"
                    class="mr-0"
                    placeholder="用户姓名"
                    type="text"
                    @input="searchDialogList('left')"
                  />
                </div>
                <div class="flex-y-center mr-1" style="width:120px;">
                  <form-item
                    v-model="activation.left.search.data.roleName"
                    class="mr-0"
                    placeholder="组织角色"
                    type="text"
                    @input="searchDialogList('left')"
                  />
                </div>
                <v-btn dark small title="筛选" color="primary" depressed min-width="34" height="30" style="padding:0;" @click="searchDialogList('left')">
                  <v-icon size="18">mdi-magnify</v-icon>
                </v-btn>
                <v-btn dark small color="grey lighten-1" class=" mx-1" title="重置筛选" depressed min-width="34" height="30" style="padding:0;" @click="activation.left.search.data={},searchDialogList('left')">
                  <v-icon size="18">mdi-restore</v-icon>
                </v-btn>
              </div>

              <!-- allUserList.filter(_t => !(activation.userIdList.includes(_t.id))).slice((activation.left.search.pageSize*(activation.left.search.pageNo-1)),(activation.left.search.pageSize*activation.left.search.pageNo)) -->
              <v-data-table
                :headers="activation.headers"
                :items="activation.left.showList.slice((activation.left.search.pageSize*(activation.left.search.pageNo-1)),(activation.left.search.pageSize*activation.left.search.pageNo))"
                :items-per-page="activation.left.search.pageSize"
                class="pb-5 max-width header-grey"
                hide-default-footer
                item-key="id"
                no-data-text="暂无查询数据"
                :loading="listLoading"
                disable-sort
                dense
              >
                <template v-slot:header.checkbox>
                  <v-checkbox
                    v-model="activation.left.selectedAll"
                    class="mt-0 py-2"
                    dense
                    label="全选"
                    hide-details
                    @change="(e)=>{selectedAll(e,'left')}"
                  />
                </template>
                <template v-slot:item.rowIndex="{index}">
                  {{ (index+1)+(activation.left.search.pageSize*(activation.left.search.pageNo-1)) }}
                </template>
                <template v-slot:item.checkbox="{item}">
                  <v-checkbox
                    v-model="activation.left.selected"
                    class="mt-0 pt-0"
                    dense
                    hide-details
                    :value="item.id"
                    @change="selectedIt('left')"
                  />
                </template>
                <template v-slot:item.roleName="{item}">
                  <div :title="item.roleName" class="font-overflow" style="max-width:170px;">{{ item.roleName }}</div>
                </template>
              </v-data-table>
              <div class="mt-2 pr-2 mb-5 max-width  flex-justify-end">
                <!-- @changePageSize="(e)=>{activation.left.search.pageSize=e}" -->

                <!-- allUserList.filter(_t => !(activation.userIdList.includes(_t.id))).length -->
                <pagination
                  v-model="activation.left.search.pageNo"
                  :size="activation.left.search.pageSize"
                  :total="activation.left.total"
                  @changePageSize="(e)=>{activation.left.search.pageSize=e,$forceUpdate()}"
                />
              </div>
            </div>
            <div class=" flex-0 flex-shrink-0 flex-center flex-column" style="width:100px;">
              <v-btn color="primary" height="30" class="mb-2" depressed :disabled="!activation.left.selected.length" @click="operationUser('left')">
                添加<v-icon size="18">mdi-arrow-right</v-icon></v-btn>
              <v-btn color="error" height="30" depressed :disabled="!activation.right.selected.length" @click="operationUser('right')">
                移除<v-icon size="18">mdi-arrow-left</v-icon></v-btn>
            </div>
            <div class=" flex-column flex-1 overflow-auto" style="border:1px solid #f2f2f2;">
              <div class="flex-justify-end flex-y-center py-2">
                <div class="flex-y-center mr-1" style="width:120px;">
                  <form-item
                    v-model="activation.right.search.data.username"
                    class="mr-0"
                    placeholder="用户账号"
                    type="text"
                    @input="searchDialogList('right')"
                  />
                </div>
                <div class="flex-y-center mr-1" style="width:120px;">
                  <form-item
                    v-model="activation.right.search.data.realname"
                    class="mr-0"
                    placeholder="用户姓名"
                    type="text"
                    @input="searchDialogList('right')"
                  />
                </div>
                <div class="flex-y-center mr-1" style="width:120px;">
                  <form-item
                    v-model="activation.right.search.data.roleName"
                    class="mr-0"
                    placeholder="组织角色"
                    type="text"
                    @input="searchDialogList('right')"
                  />
                </div>
                <v-btn dark small title="筛选" color="primary" depressed min-width="34" height="30" style="padding:0;" @click="searchDialogList('right')">
                  <v-icon size="18">mdi-magnify</v-icon>
                </v-btn>
                <v-btn dark small color="grey lighten-1" class=" mx-1" title="重置筛选" depressed min-width="34" height="30" style="padding:0;" @click="activation.right.search.data={},searchDialogList('right')">
                  <v-icon size="18">mdi-restore</v-icon>
                </v-btn>
              </div>
              <v-data-table
                :headers="activation.headers"
                :items="activation.right.showList.slice((activation.right.search.pageSize*(activation.right.search.pageNo-1)),(activation.right.search.pageSize*activation.right.search.pageNo))"
                :items-per-page="activation.right.search.pageSize"
                class="pb-5 max-width header-grey "
                hide-default-footer
                item-key="id"
                no-data-text="暂无查询数据"
                :loading="listLoading"
                disable-sort
                dense
              >
                <template v-slot:header.checkbox>
                  <v-checkbox
                    v-model="activation.right.selectedAll"
                    class="mt-0 py-2"
                    dense
                    label="全选"
                    hide-details
                    @change="(e)=>{selectedAll(e,'right')}"
                  />
                </template>
                <template v-slot:item.rowIndex="{index}">
                  {{ (index+1)+(activation.right.search.pageSize*(activation.right.search.pageNo-1)) }}
                </template>
                <template v-slot:item.checkbox="{item}">
                  <v-checkbox
                    v-model="activation.right.selected"
                    class="mt-0 pt-0"
                    dense
                    hide-details
                    :value="item.id"
                    @change="selectedIt('right')"
                  />
                </template>
                <template v-slot:item.roleName="{item}">
                  <div :title="item.roleName" class="font-overflow" style="max-width:170px;">{{ item.roleName }}</div>
                </template>
              </v-data-table>
              <div class="mt-2 pr-2 mb-5 max-width  flex-justify-end">
                <!-- @changePageSize="changePageSize" -->
                <pagination
                  v-model="activation.right.search.pageNo"
                  :size="activation.right.search.pageSize"
                  :total="activation.right.total"
                  @changePageSize="(e)=>{activation.right.search.pageSize=e,$forceUpdate()}"
                />
              </div>
            </div>
          </div>

          <!-- <div class="flex-1 flex-column overflow-auto px-3">
            <v-row
                class="mb-6"
                no-gutters
              >
                <v-col
                  v-for="(item,index) in allUserList"
                  :key="index"
                  :md="4"
                >
                  <v-checkbox
                    style="height:32px;margin-top:10px;"
                    v-model="activation.userIdList"
                    :label="item.realname"
                    :value="item.id"
                  ></v-checkbox>
                </v-col>
            </v-row>

          </div> -->
        </v-card-text>
        <v-divider />
        <v-card-actions class=" flex-0 flex-shrink-0">
          <div class="max-width flex-y-center flex-justify-between">
            <div class="flex-1 flex-justify-end">
              <v-btn width="100" height="30" class depressed @click="activation.show = false">取消</v-btn>
              <v-btn
                width="100"
                :loading="activation.btnLoading"
                class="ml-2"
                height="30"
                color="primary"
                depressed
                @click.stop="sureActivation"
              >确定</v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 添加已有用户 end -->

  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      tab: '1',
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      total: 0,
      allTrees: [],
      active: [],
      trees: [],
      open: [],
      selection: [],
      selectionChecksF: false,
      treeSearch: null,
      content: {
        data: {},
        departId: []
      },
      allUserList: [],
      headers: [
        { text: '序号', value: 'rowIndex', width: '50', sortable: false },
        { text: '用户账号', value: 'username' },
        { text: '用户姓名', value: 'realname' },
        // { text: "性别", value: "sex_dictText" },
        { text: '邮箱', value: 'email' },
        { text: '联系电话', value: 'phone' },
        { text: '组织角色', value: 'roleName' },
        { text: '状态', value: 'status' },
        { text: '操作', value: 'active', sortable: false }
      ],
      list: [],
      showList: [],
      btnLoading: false,
      listLoading: false,
      selected: [],
      activation: {
        show: false,
        userIdList: [],
        left: {
          search: {
            pageSize: 10,
            pageNo: 1,
            data: {}
          },
          total: 0,
          selectedAll: false,
          selected: [],
          showList: []
        },
        right: {
          search: {
            pageSize: 10,
            pageNo: 1,
            data: {}
          },
          total: 0,
          selectedAll: false,
          selected: [],
          showList: []
        },
        headers: [
          { text: '序号', value: 'rowIndex', width: '50', sortable: false },
          { text: '选择', value: 'checkbox', width: '40px', sortable: false },
          { text: '用户账号', value: 'username' },
          { text: '用户姓名', value: 'realname', width: '80px' },
          { text: '组织角色', value: 'roleName' }
        ],
        listLoading: false,
        btnLoading: false
      },
      departmentDialog: {
        show: false,
        loading: false,
        type: '',
        data: {},
        departId: []
      }
    }
  },
  computed: {
    treeFilter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    }
  },
  watch: {
    selection: function(newval, oldval) {
      if (this.selectionChecksF) {
        this.selectionChecksF = false
        const the = this
        // let childIds = []   //新增 删除 部门底下的所有人员id
        let list = []
        let v = []
        // let type = 1
        if (newval.length > oldval.length) {
          list = newval
          v = oldval
          // type = 1
        } else {
          list = oldval
          v = newval
          // type = 0
        }
        let ff = false
        // let c=[]
        // console.log(type)
        const z = (arr) => {
          arr.forEach(_z => {
            if (newval.length > oldval.length) {
              if (the.selection.indexOf(_z.id) === -1) {
                the.selection.push(_z.id)
              }
            } else {
              if (the.selection.indexOf(_z.id) !== -1) {
                the.selection.splice(the.selection.indexOf(_z.id), 1)
              }
            }
            if (_z.children && _z.children.length) {
              z(_z.children)
            }
          })
        }

        list.forEach((item) => {
          if (v.indexOf(item) === -1) {
            const _h = (arr) => {
              arr.forEach((_item) => {
                if (!ff) {
                  if (item === _item.id) {
                    ff = true
                    _item.children && _item.children.length && z(_item.children)
                  } else {
                    if (_item.children && _item.children.length) {
                      _h(_item.children)
                    }
                  }
                }
              })
            }
            _h(this.allTrees)
          }
        })
      }
    }
  },
  mounted() {
    // this.getQueryDepartTreeSync();
    this.getSysDepartQueryTreeList()
    this.getAllUserList()
  },
  methods: {
    // 部门
    // getQueryDepartTreeSync(id) {
    //   this.$http.get(this.$api.queryDepartTreeSync, { data: { pid: id||"" } }).then(res => {

    //       let _f=false
    //       let _h=(arr)=>{
    //         arr.forEach(item=>{
    //           if(!_f){
    //             if(item.id==id){
    //               _f=true
    //               item.children=res.result
    //             }else{
    //               if(item.children&&item.children.length) _h(item.children)
    //             }
    //           }
    //         })
    //       }
    //       if(res.result&&res.result.length){
    //         res.result.forEach(item=>{
    //           if(item.isLeaf) item.children=null
    //         })
    //       }
    //       if(id){
    //         let _open=[...this.open]
    //         if(_open.indexOf(id)==-1){
    //           _open.push(id)
    //         }
    //         // this.$set(this.open,_open)
    //         this.open=_open
    //         if(res.result&&res.result.length) _h(this.trees)
    //       }else{
    //         this.active=[]
    //         this.open=[]
    //         this.selection=[]
    //         this.trees=[]
    //         this.trees = res.result || [];
    //       }

    //   });
    // },
    // 部门 树 全部部门
    getSysDepartQueryTreeList() {
      this.$http.get(this.$api.sysDepartQueryTreeList, {}).then(res => {
        // item-disabled="locked" disabled
        this.allTrees = res.result || []
        this.setOpen('init') // 树形全部展开
        if (this.active && this.active.length) {
          this.chooseTree(this.active)
        } else {
          if (this.allTrees && this.allTrees.length) {
            this.active = [this.allTrees[0].id]
            // this.chooseTree(this.active)  //默认选中第一个部门
          }
        }
      })
    },
    // 获取所有用户
    getAllUserList() {
      this.$http.get(this.$api.userList, { data: { userCategory: '1', pageSize: 99999, pageNo: 1 }}).then(res => {
        this.allUserList = res.result.records || []
      })
    },
    initList() {
      this.search.pageNo = 1
      this.getDepartUserList()
    },
    // 改变每页的条数
    changePageSize(e) {
      this.search.pageSize = e
      this.search.pageNo = 1
      this.$forceUpdate()
      this.getDepartUserList()
    },
    // departUserList 用户-查询指定用户部门
    getDepartUserList() {
      this.search.data = {}
      const _data = { ...this.search.data }
      _data.pageSize = 999
      _data.pageNo = 1
      // _data.deptId = this.content.data.id;
      _data.id = this.content.data.id

      const param = new URLSearchParams()
      for (const i in _data) {
        if (_data[i]) param.append(i, _data[i])
      }
      this.activation.userIdList = []
      this.listLoading = true
      // this.$http.get(this.$api.departUserList, { data: _data }).then(res => {
      this.$http.get(this.$api.getUsersByDepartId, { data: _data }).then(res => {
        this.listLoading = false
        this.list = res.result && res.result || []
        this.showList = JSON.parse(JSON.stringify(this.list))
        this.activation.userIdList = this.list.map(_t => { return _t.id })
        this.total = this.list.length || 0
        // this.total = res.result.total||0
      })
    },
    // 获取用户列表
    // getUserList(id) {
    //   let _data = { ...this.search.data };
    //   _data.pageSize = this.search.pageSize;
    //   _data.pageNo = this.search.pageNo;
    //   let _f = false;
    //   let _orgCodeTxt = "";
    //   let _h = arr => {
    //     arr.forEach(item => {
    //       if (!_f) {
    //         if (item.id == this.active[0]) {
    //           _orgCodeTxt = item.departName;
    //           _f = true;
    //         } else {
    //           if (item.children && item.children.length) _h(item.children);
    //         }
    //       }
    //     });
    //   };
    //   // _h(this.trees)
    //   _h(this.allTrees)
    //   _data.orgCodeTxt = _orgCodeTxt;
    //   _data.userCategory='1'
    //   let param = new URLSearchParams();
    //   for (let i in _data) {
    //     if(_data[i]) param.append(i, _data[i])
    //   }
    //   // this.$http.get(this.$api.userList, { data: param }).then(res => {
    //   //     this.list = res.result.records || [];
    //   // });
    //   this.$http.get(this.$api.getUsersByDepartId, { data: {id:id} }).then(res => {
    //       this.list = res.result || [];
    //   });

    // },
    // 展开左侧树形
    setOpen(type) {
      if (this.treeSearch || type === 'init') {
        const _open = []
        const _h = (arr) => {
          arr.forEach(item => {
            if (_open.indexOf(item.id) === -1) _open.push(item.id)
            if (item.children && item.children.length) _h(item.children)
          })
        }
        if (this.allTrees.length) _h(this.allTrees)
        this.open = _open
      }
    },

    // 部门树 加载子部门
    // fetchTrees(e){
    //   console.log(e)
    //   // this.getQueryDepartTreeSync(e.id)
    //   this.getSysDepartQueryTreeList()
    // },
    // 点击树的复选框
    testtest() {
      this.selectionChecksF = true
    },
    // 左侧树 点击
    chooseTree(e) {
      this.content.departId = []
      this.content.data = {}
      if (e && e.length) {
        let _data = {}
        // let _f=false
        const _h = (arr) => {
          arr.forEach(item => {
            this.$set(item, '_disabled', false)
            // if(!_f){
            if (item.id === e[0]) {
              this.$set(item, '_disabled', true)
              // _f=true
              _data = { id: item.id, departName: item.departName, parentId: item.parentId }
            } else {
              if (item.children && item.children.length) _h(item.children)
            }
            // }
          })
        }
        // if(_data.parentId) this.content.departId.push(_data.parentId)
        // _h(this.trees)
        _h(this.allTrees)
        if (_data.parentId) this.content.departId.push(_data.parentId)
        this.content.data = _data
        // this.getUserList(this.content.data.id);
        // this.getDepartUserList(this.content.data.id);
        this.initList()
      } else {
        this.list = []
      }
    },

    // 选择用户 按钮 弹窗
    openActivation() {
      this.activation.userIdList = (this.list.map(_t => { return _t.id })) || []
      this.activation.left.search.pageNo = 1
      this.activation.left.selected = []
      this.activation.left.selectedAll = false
      this.activation.right.selected = []
      this.activation.right.selectedAll = false
      this.activation.right.search.pageNo = 1
      this.activation.show = true

      this.activation.left.showList = this.allUserList.filter(_t => !(this.activation.userIdList.includes(_t.id)))
      this.activation.left.total = this.activation.left.showList.length || 0
      this.activation.right.showList = this.allUserList.filter(_t => (this.activation.userIdList.includes(_t.id)))
      this.activation.right.total = this.activation.right.showList.length || 0
    },
    // 已选择的成员列表 筛选
    searchLoading() {
      // this.list  username  realname
      let _a = []
      this.search.pageNo = 1
      if (this.list && this.list.length) {
        _a = [...this.list].filter(_r => {
          if ((_r.username ? (_r.username.indexOf(this.search.data.username || _r.username) !== -1) : 1) &&
          (_r.realname ? (_r.realname.indexOf(this.search.data.realname || _r.realname) !== -1) : 1)) {
            return _r
          }
        })
      }
      this.showList = _a
      this.total = this.showList.length || 0
    },
    // 添加已有用户 弹窗 全选
    selectedAll(e, type) {
      if (e) {
        let _a = []

        _a = this.activation[type].showList
        // if(type=='left'){
        //   _a=this.allUserList.filter(_t => !(this.activation.userIdList.includes(_t.id)))
        // }else{
        //   _a=this.allUserList.filter(_t => (this.activation.userIdList.includes(_t.id)))
        // }
        this.activation[type].selected = _a.map(_t => { return _t.id })
      } else {
        this.activation[type].selected = []
      }
    },
    // 添加已有用户 弹窗 单选
    selectedIt(type) {
      console.log(type)
    },
    // 添加已有用户 向左 向右 操作
    operationUser(type) {
      this.activation.left.search.pageNo = 1
      this.activation.right.search.pageNo = 1
      if (type === 'left') { // 左边到右边
        this.activation[type].selected.forEach(item => {
          if (this.activation.userIdList.indexOf(item) === -1) {
            this.activation.userIdList.push(item)
          }
        })
        this.activation.right.search.data = {}
        this.activation.right.showList = this.allUserList.filter(_t => (this.activation.userIdList.includes(_t.id)))
        this.activation.right.total = this.activation.right.showList.length || 0
      } else {
        this.activation[type].selected.forEach(item => {
          if (this.activation.userIdList.indexOf(item) !== -1) {
            this.activation.userIdList.splice(this.activation.userIdList.indexOf(item), 1)
          }
        })
        this.activation.left.search.data = {}
        this.activation.left.showList = this.allUserList.filter(_t => !(this.activation.userIdList.includes(_t.id)))
        this.activation.left.total = this.activation.left.showList.length || 0
      }
      this.searchDialogList(type)
      this.activation[type].selected = []
      this.activation[type].selectedAll = false
    },
    // 添加已有用户弹窗 搜索
    searchDialogList(type) {
      this.activation[type].search.pageNo = 1
      this.activation[type].selected = []
      this.activation[type].selectedAll = false
      let _arr = []
      if (type === 'left') {
        _arr = this.allUserList.filter(_t => !(this.activation.userIdList.includes(_t.id)))
      } else {
        _arr = this.allUserList.filter(_t => (this.activation.userIdList.includes(_t.id)))
      }
      let _a = []
      if (_arr && _arr.length) {
        _a = [..._arr].filter(_r => {
          if ((_r.username ? (_r.username.indexOf(this.activation[type].search.data.username || _r.username) !== -1) : 1) &&
          (_r.realname ? (_r.realname.indexOf(this.activation[type].search.data.realname || _r.realname) !== -1) : 1) &&
          (_r.roleName ? (_r.roleName.indexOf(this.activation[type].search.data.roleName || _r.roleName) !== -1) : 1)) {
            return _r
          }
        })
      }
      this.activation[type].showList = _a
      this.activation[type].total = this.activation[type].showList.length || 0
    },

    // 删除部门
    departmentDel() {
      // if(confirm("是否确认改部门删除？")){
      //   // this.active
      //   let param = new URLSearchParams();
      //   param.append('ids', this.selection.join(','))
      //   this.$http.delete(this.$api.sysDepartDeleteBatch, { data: param }).then(res => {
      //     this.$message.success(res.message)
      //     this.open=[]
      //     if(this.active&&this.active.length){
      //        if(this.selection.includes(this.active[0])){
      //          this.active=[]
      //        }
      //     }
      //     this.selection=[]
      //     // this.getQueryDepartTreeSync();
      //     this.getSysDepartQueryTreeList()
      //   }).catch(()=>{
      //     this.selection=[]
      //   });
      // }
      this.$confirm({ text: '是否确认批量删除选中的部门？' }).then((vm) => {
        const param = new URLSearchParams()
        param.append('ids', this.selection.join(','))
        vm.loading = true
        this.$http.delete(this.$api.sysDepartDeleteBatch, { data: param }).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          this.open = []
          if (this.active && this.active.length) {
            if (this.selection.includes(this.active[0])) {
              this.active = []
            }
          }
          this.selection = []
          // this.getQueryDepartTreeSync();
          this.getSysDepartQueryTreeList()
        }).catch(() => {
          vm.show = false
          vm.loading = false
          this.selection = []
        })
      }).catch(() => {})
    },
    // 部门 编辑弹窗
    // departmentEdit() {
    //   let _f = false;
    //   let _data = {};
    //   let _h = arr => {
    //     arr.forEach(item => {
    //       if (!_f) {
    //         if (item.id == this.active[0]) {
    //           _data = { ...item };
    //           _f = true;
    //         } else {
    //           if (item.children && item.children.length) _h(item.children);
    //         }
    //       }
    //     });
    //   };
    //   // _h(this.trees);
    //   _h(this.allTrees)
    //   this.departmentDialog.data = _data;
    //   this.departmentDialog.show = true;
    // },
    // 部门 添加 编辑 确认
    departmentDialoSubmit(type) {
      let _data = {}
      let url = this.$api.sysDepartAdd
      let method = 'post'
      if (type === 'edit') {
        url = this.$api.sysDepartEdit
        method = 'put'
        _data = { ...this.content.data }
        // _data.parentId=this.content.departId.length?this.content.departId[0].id:''
        _data.parentId = this.content.departId.length ? this.content.departId[0] : ''
        this.btnLoading = true
      } else {
        _data = { ...this.departmentDialog.data }
        _data.parentId = this.departmentDialog.departId.length ? this.departmentDialog.departId[0].id : ''
      }
      this.departmentDialog.loading = true
      this.$http[method](url, { data: _data }).then(res => {
        this.$message.success(res.message)
        this.departmentDialog.show = false
        this.departmentDialog.data = {}
        this.open = []
        this.selection = []

        // if(type!='edit') this.getQueryDepartTreeSync();
        // if(type!='edit') this.getSysDepartQueryTreeList();
        if (type === 'edit') {
          // this.active=[]
          this.getSysDepartQueryTreeList('edit')
        } else {
          this.getSysDepartQueryTreeList()
        }
        this.departmentDialog.loading = false
        this.btnLoading = false
      }).catch(() => {
        this.departmentDialog.loading = false
        this.btnLoading = false
      })
    },
    // operation($api.deleteUserInDepart,'delete','移除',{id:item.id})
    // 将用户从部门移除 operation
    operation(api, method, title, params) {
      // if(confirm('是否确认'+title+'？')){
      //   let param = new URLSearchParams();
      //   for (let i in params) {
      //     if(params[i]) param.append(i, params[i])
      //   }
      //   this.$http[method](api, { data: param }).then(res => {
      //       this.$message.success(res.message)
      //       this.getDepartUserList()
      //   }).catch(()=>{
      //     this.getDepartUserList()
      //   });
      // }
      this.$confirm({ text: '是否确认' + title + '？' }).then((vm) => {
        const param = new URLSearchParams()
        for (const i in params) {
          if (params[i]) param.append(i, params[i] || '')
        }
        vm.loading = true
        this.$http[method](api, { data: param }).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          // this.getDepartUserList()
          this.initList()
        }).catch(() => {
          vm.show = false
          vm.loading = false
          // this.getDepartUserList()
          this.initList()
        })
      }).catch(() => {})
    },

    // 确认给指定部门添加对应的用户
    sureActivation() {
      this.activation.btnLoading = true
      this.$http.post(this.$api.editSysDepartWithUser, { data: { depId: this.content.data.id, userIdList: this.activation.userIdList }}).then(res => {
        this.$message.success(res.message)
        this.activation.show = false
        this.activation.btnLoading = false
        // this.getDepartUserList()
        this.initList()
      }).catch(() => {
        // this.getDepartUserList()
        this.initList()
        this.activation.btnLoading = false
      })
    },

    // 部门基本信息 返回父级部门文字
    backParentName() {
      let _text = '-'
      let _f = false
      const _h = (arr) => {
        arr.forEach(item => {
          if (!_f) {
            if (item.id === this.content.departId[0]) {
              _f = true
              _text = item.departName
            } else {
              if (item.children && item.children.length) _h(item.children)
            }
          }
        })
      }
      // _h(this.trees)
      _h(this.allTrees)
      return _text
    }
    // <span v-if="content.departId&&content.departId.length" v-text="backParentName"></span>
  }
}
</script>

<style lang="scss" scoped>
.form-title {
  width: 130px;
  color: #777;
}
</style>
